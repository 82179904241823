import React, { useEffect } from "react";
import { Typography, Box, Grid, Container, Paper } from "@mui/material";
import featuredImage from "images/featuredImage.svg";
import HubspotForm from "components/HubspotForm";
import Image from "@jy95/material-ui-image";
import TagManager from "react-gtm-module";
import VideocamIcon from "@mui/icons-material/Videocam";

function GateView() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: "Welcome",
        pageTitle: "Welcome page with lead converter form",
      },
    });
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} alignItems="center" sx={{ height: "100vh" }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: { xs: 5, md: 0 } }}>
            <Typography
              align="center"
              variant="h3"
              sx={{ textTransform: "none", color: "#fafafa" }}
            >
              <VideocamIcon
                sx={{
                  mr: 1,
                  mb: -0.5,
                }}
              />
              Vídeos de iflares.com
            </Typography>
            <Typography
              align="center"
              variant="h1"
              sx={{
                fontSize: 60,
                lineHeight: 0.8,
                fontWeight: 700,
                color: "primary.main",
                pb: 1,
              }}
            >
              Digitalización de procesos
            </Typography>
            <Image
              alt="iflares-video"
              src={featuredImage}
              color="transparent"
              aspectRatio={1.85}
            />
            <Typography
              align="center"
              sx={{ fontSize: 20, maxWidth: "95%", margin: "auto" }}
            >
              Bienvenido a la plataforma de videos sobre digitalización de las
              operaciones.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              backgroundColor: "#f5f5f5",
              mt: 2,
              color: "black",
              borderRadius: 2,
            }}
          >
            <HubspotForm />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default GateView;
