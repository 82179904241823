import React from "react";
import { Typography, Box, Button } from "@mui/material";
import useHeight from "theme/layout";
import TodayIcon from "@mui/icons-material/Today";

function BelowChatCalendarView({ video }) {
  const height = useHeight;
  return (
    <>
      <Box
        justifyContent="center"
        button
        alignItems="center"
        sx={{
          px: 3,
          pt: 2,
          borderTop: "1px solid",
          backgroundColor: "secondary.main",
          borderBottom: "1px solid",
          borderColor: "#666666",
          height: `${
            height.disabledSendMessageButtonBox +
            height.enabledSendMessageButtonBox
          }px`,
        }}
      >
        <Typography variant="h4" align="center">
          {video.language === "es" ? "¿Tienes dudas?" : "Got any questions?"}
        </Typography>
        <Typography paragraph align="center">
          {video.language === "es"
            ? "Habla con un experto."
            : "Talk to an expert."}
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          size="large"
          fullWidth
          href="https://meetings.hubspot.com/maria-baute"
          target="_blank"
          startIcon={<TodayIcon />}
        >
          {video.language === "es" ? "Ver calendario" : "Open calendar"}
        </Button>
      </Box>
    </>
  );
}

export default BelowChatCalendarView;
