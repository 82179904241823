const Gate = {
  code: "demo-webinar",
  defaultCallToActionUrl: "https://iflares.com/",
  hubspotForm: {
    portalId: "6817440",
    formId: "36040329-bc3d-4dae-8588-a8cf82def69a",
  },
  defaultText: {
    overline: "Contenido Exclusivo",
    title: "Accede a los vídeos",
    subtitle: "Rellena el formulario para acceder al contenido",
    enticement: "En menos de 2 segundos",
    privacyNotice:
      "iflares.com necesita esta información de contacto para darte acceso al contenido. Puedes ejercer tus derechos de desestimiento en cualquier momento. Más información sobre prácticas de privacidad y nuestro compromiso de proteger tu privacidad en nuestra <a href='https://iflares.com/politica-de-privacidad' target='_blank'>Política de privacidad</a>.",
    companyTagline: "Digitalización de las operaciones.",
    chatBubbleHelp: "¿Tienes dudas?",
  },
};

export default Gate;
