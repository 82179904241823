import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import useHeight from "theme/layout";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function MenuView({ video }) {
  const height = useHeight;
  return (
    <AppBar
      sx={{
        height: `${height.menu}px`,
        position: { xs: "fixed", sm: "sticky", md: "fixed" },
      }}
    >
      <Toolbar>
        <IconButton component={Link} to={`/watch/tableOfContents`}>
          <ArrowBackIcon fontSize="large" color="primary" />
        </IconButton>
        <Box flexGrow={1}>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  noWrap
                  sx={{ fontWeight: "bold", maxWidth: "75vw" }}
                >
                  {video.title}
                </Typography>
              }
              secondary={
                <Typography noWrap sx={{ maxWidth: "75vw" }}>
                  {video.subtitle}
                </Typography>
              }
            />
          </ListItem>
        </Box>
        <Button
          variant="outlined"
          href="https://new.iflares.com/user/login"
          color="inherit"
          target="_blank"
          size="large"
          startIcon={<MeetingRoomIcon />}
          sx={{ visibility: { xs: "hidden", md: "visible" } }}
        >
          Login
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default MenuView;
