import E4f0w_LUHj4 from "images/E4f0w_LUHj4.jpg";
import spXaJ7Tv3E from "images/8spXaJ7Tv3E.jpg";
import d0AhrM_fOxk from "images/d0AhrM_fOxk.webp";
import ZomMmIkvEjQ from "images/ZomMmIkvEjQ.jpg";
import g3IprANnLS4 from "images/g3IprANnLS4.jpg";
import VFyHC6p1kRw from "images/VFyHC6p1kRw.jpg";
const Videos = [
  {
    code: "Raul-Garrido-Food-Delivery-Brands",
    youtubeId: "VFyHC6p1kRw",
    listed: true,
    startAt: 1,
    language: "es",
    title: "Raul Garrido, Director de Operaciones de Food Delivery Brands",
    subtitle:
      "Conoce a Raul Garrido, quien nos explica su trayectoria y su trabajo reciente en Food Delivery Brands.",
    avatar: VFyHC6p1kRw,
    chat: [
      {
        fullName: "María Miranda",
        t: 0,
        content:
          'Puedes encontrar más información sobre Raúl Garrido en <a target="_blank" href="https://www.linkedin.com/in/raulgarridodelavega/">su perfil de Linkedin</a>.',
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 0,
        content: `También podéis ver su <a target="_blank" href="https://www.fooddeliverybrands.com/en/">página web</a>.`,
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 400000,
        content:
          'También puedes contactar con iflares.com en <a target="_blank" href="mailto:ventas@iflares.com?subject=¿Cómo ayuda iflares.com a mi empresa??&body=He visto un vídeo y quiero más información sobre iflares.">ventas@iflares.com</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Macarena-Charlo-Tastia",
    youtubeId: "g3IprANnLS4",
    listed: true,
    startAt: 1,
    language: "es",
    title:
      "Macarena Charlo, Directora Corporativa División de Restauración en Tastia Group",
    subtitle:
      "En esta entrevista, Macarena Charlo nos explica su trayectoria y la labor de Tastia.",
    avatar: g3IprANnLS4,
    chat: [
      {
        fullName: "María Miranda",
        t: 0,
        content:
          'Puedes encontrar más información sobre Macarena Charlo en <a target="_blank" href="https://www.linkedin.com/in/macarena-charlo-de-paul/">su perfil de Linkedin</a>.',
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 0,
        content: `También podéis ver su <a target="_blank" href="https://www.tastiagroup.com/">página web</a>.`,
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 400000,
        content:
          'También puedes contactar con iflares.com en <a target="_blank" href="mailto:ventas@iflares.com?subject=¿Cómo ayuda iflares.com a mi empresa??&body=He visto un vídeo y quiero más información sobre iflares.">ventas@iflares.com</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Jesus-Collado-Bidfood-Spain",
    youtubeId: "ZomMmIkvEjQ",
    listed: true,
    startAt: 1,
    language: "es",
    title: "Jesús Collado, General Manager en Bidfood Spain",
    subtitle:
      "En esta entrevista, Jesús Collado nos explica su trayectoria y la labor de Bidfood Spain.",
    avatar: ZomMmIkvEjQ,
    chat: [
      {
        fullName: "María Miranda",
        t: 0,
        content:
          'Puedes encontrar más información sobre Jesús Collado en <a target="_blank" href="https://www.linkedin.com/in/jesuscollado/">su perfil de Linkedin</a>.',
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 0,
        content: `También podéis ver su <a target="_blank" href="https://bidfoodiberia.com/">página web</a>.`,
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 400000,
        content:
          'También puedes contactar con iflares.com en <a target="_blank" href="mailto:ventas@iflares.com?subject=¿Cómo ayuda iflares.com a mi empresa??&body=He visto un vídeo y quiero más información sobre iflares.">ventas@iflares.com</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "Juanjo-Castellvi-Bidfood-iberia",
    youtubeId: "8spXaJ7Tv3E",
    listed: true,
    startAt: 1,
    language: "es",
    title: "Juanjo Castellvi, Director General de Bidfood Iberia",
    subtitle:
      "En esta entrevista, Juanjo Castellvi nos explica su trayectoria y la labor de Bidfood Spain.",
    avatar: spXaJ7Tv3E,
    chat: [
      {
        fullName: "María Miranda",
        t: 0,
        content:
          'Puedes encontrar más información sobre Juanjo Castellvi en <a target="_blank" href="https://www.linkedin.com/in/juanjo-castellvi-5944aa65/">su perfil de Linkedin</a>.',
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 0,
        content: `También podéis ver su <a target="_blank" href="https://bidfoodiberia.com/">página web</a>.`,
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 400000,
        content:
          'También puedes contactar con iflares.com en <a target="_blank" href="mailto:ventas@iflares.com?subject=¿Cómo ayuda iflares.com a mi empresa??&body=He visto un vídeo y quiero más información sobre iflares.">ventas@iflares.com</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "manel-morillo-con-gusto-consulting",
    youtubeId: "E4f0w_LUHj4",
    listed: true,
    startAt: 1,
    language: "es",
    title: "Manel Morillo, Socio fundador de Con gusto Consulting",
    subtitle:
      "En esta entrevista, Manel Morillo nos explica su trayectoria y la labor de Con gusto Consulting.",
    avatar: E4f0w_LUHj4,
    chat: [
      {
        fullName: "María Miranda",
        t: 0,
        content:
          'Puedes encontrar más información sobre Manel Morillo en <a target="_blank" href="https://www.linkedin.com/in/manelmorillo/">su perfil de Linkedin</a>.',
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 0,
        content: `También podéis ver su <a target="_blank" href="https://www.congustoconsulting.com">página web</a>.`,
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 400000,
        content:
          'También puedes contactar con iflares.com en <a target="_blank" href="mailto:ventas@iflares.com?subject=¿Cómo ayuda iflares.com a mi empresa??&body=He visto un vídeo y quiero más información sobre iflares.">ventas@iflares.com</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
  {
    code: "tutorial-completo",
    youtubeId: "d0AhrM_fOxk",
    listed: false,
    startAt: 1,
    language: "es",
    title: "Tutorial completo de iflares.com",
    subtitle:
      "Nuestro CEO, Jorge Gomez, muestra un tutorial completo de la herramienta iflares.com.",
    avatar: d0AhrM_fOxk,
    chat: [
      {
        fullName: "María Miranda",
        t: 0,
        content:
          'Puedes encontrar más información sobre Jorge Gómez en <a target="_blank" href="https://www.linkedin.com/in/jorge-g%C3%B3mez-iflares/">su perfil de Linkedin</a>.',
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 0,
        content: `También podéis ver su <a target="_blank" href="https://iflares.com/">página web</a>.`,
        type: "person",
      },
      {
        fullName: "María Miranda",
        t: 400000,
        content:
          'También puedes contactar con iflares.com en <a target="_blank" href="mailto:ventas@iflares.com?subject=¿Cómo ayuda iflares.com a mi empresa??&body=He visto un vídeo y quiero más información sobre iflares.">ventas@iflares.com</a>.',
        type: "person",
      },
      {
        fullName: false,
        t: 600000,
        content:
          "El administrador cambió los permisos de visibilidad. Ahora el chat es privado.",
        type: "system",
      },
    ],
  },
];

export default Videos;
