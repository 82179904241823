import d0AhrM_fOxk from "images/d0AhrM_fOxk.webp";
import moment from "moment";

let now = Date.now();

const startDatetime = new Date(now + 604800000);
const endDatetime = new Date(now + 604800000 + 2400000);
const duration = startDatetime.getHours() - endDatetime.getHours();

const Featured = {
  code: "tutorial-completo-de-iflares",
  youtubeId: "d0AhrM_fOxk",
  callToAction: {
    href: "https://iflares.com/6-hacks-auditorias-de-calidad-sean-infalibles",
  },
  startAt: 90,
  language: "es-es",
  title: "6 Hacks para auditorías",
  subtitle: "Para que tus auditorías de calidad sean infalibles",
  description:
    "Las auditorías son una serie de controles periódicos que se hacen para asegurar el cumplimiento de procedimientos estándar establecidos, para el buen funcionamiento de las actividades diarias del restaurante. Hoy te explicamos 6 hacks para que tus auditorías de calidad sean infalibles en tu restaurante.",
  airDate: startDatetime,
  avatar: d0AhrM_fOxk,
  chat: false,
  event: null,
  _event: {
    title: "6 Hacks para auditorías",
    description:
      "https://iflares.com/6-hacks-auditorias-de-calidad-sean-infalibles",
    location: "6 Hacks para auditorías",
    invitees: "6 Hacks para auditorías",
    duration: duration,
    endDatetime: moment(endDatetime).format("YYYYMMDDTHHmmssZ"),
    startDatetime: moment(startDatetime).format("YYYYMMDDTHHmmssZ"),
  },
};

export default Featured;
